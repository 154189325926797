import {PageType} from "../../utils/PageType";
import React, {useEffect, useRef} from "react";
import {UserInfo} from "../../dto/UserInfo";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth"
import {auth} from "../../utils/firebase";
import {CampaignService} from "../../services/CampaignService";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css';

interface PhoneComponentProps {
    setPageType: (pageType: PageType) => void;
    userInfo: UserInfo;
    setUserInfo: (userInfo: UserInfo) => void;
}

const PhoneComponent : React.FC<PhoneComponentProps> = ({ setPageType, userInfo, setUserInfo }) => {

    const { t } = useTranslation();

    const recaptchaRef = useRef<RecaptchaVerifier | undefined>(undefined);

    const onSignInSubmit = async () => {
        toast.dismiss();

        try {
            const isPhoneRedeemed = await CampaignService.isPhoneRedeemed(userInfo.phone.replaceAll(" ", ""));

            if(isPhoneRedeemed) {
                toast.error(t("alreadyRedeemedPhone"));
                return;
            }

            signInWithPhoneNumber(auth, userInfo.phone.replaceAll(" ", ""), recaptchaRef.current!)
                .then((confirmationResult) => {
                    setUserInfo({
                        ...userInfo,
                        phone: userInfo.phone.replaceAll(" ", ""),
                        confirmationResult: confirmationResult
                    })

                    setPageType(PageType.PHONE_VERIFICATION);
                }).catch((error) => {
                toast.error(t("waitForConfirmation"))
            });
        } catch (e) {
            toast.error((e as Error).message);
        }
    }

    const verifyRecaptcha = () => {
        recaptchaRef.current = new RecaptchaVerifier(auth, 'recaptcha-container-id', {
            'size': 'invisible'
        });

        recaptchaRef.current!.render().then(() => console.log("Rendered reCAPTCHA"))
    }

    useEffect(() => {
        verifyRecaptcha()
    }, []);

    return (
        <div className={"w-screen h-screen flex flex-col items-center"}>
            <div className={"w-[90%] flex flex-col items-start mt-[7.5%]"}>
                <div className={"w-7 h-7 rounded-full bg-[#FAFAFC] flex items-center justify-center"} onClick={() => setPageType(PageType.WELCOME)}>
                    <img src={"./assets/images/back-arrow.svg"} alt={"back"}/>
                </div>
                <div className={"flex flex-col mt-[7.5%] w-full"}>
                    <p className={"font-gilroy-bold text-3xl text-black"}>{t('enterPhoneHeader')}</p>
                    <p className={"font-gilroy-semibold text-lg text-[#BFBFB9] mt-[1%]"}>{t('enterPhoneDescription')}</p>
                    <div className={"flex flex-row mt-[7.5%] text-2xl text-black font-gilroy-bold w-full"}>
                        <form>
                            <PhoneInput
                                flagUrl={"https://hatscripts.github.io/circle-flags/flags/{xx}.svg"}
                                defaultCountry="SA"
                                value={userInfo.phone}
                                onChange={(value) => setUserInfo({ ...userInfo, phone: value! })}
                                autoComplete={"tel"}
                                country
                                international
                                countries={["AE", "SA"]}
                                internationalIcon={() => <img src={"./assets/images/international.png"} className={"w-10"}></img>}
                            />
                        </form>
                    </div>
                </div>
                <div className={"mt-[10%] w-full flex flex-row justify-center items-center"}>
                    <div className={"flex flex-row items-center justify-center w-52 h-14 rounded-xl bg-[#E85929] select-none"} onClick={() => onSignInSubmit()}>
                        <p className="font-gilroy-semibold text-xl text-white">
                            {t('nextButton')}
                        </p>
                    </div>
                </div>
                <div id="recaptcha-container-id"></div>
            </div>
        </div>
    )
}

export default PhoneComponent;
