import {PageType} from "../../../utils/PageType";
import React, {useState} from "react";
import {UserInfo} from "../../../dto/UserInfo";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import OTPInput, {InputProps} from "react-otp-input";

interface PhoneVerificationComponentProps {
    setPageType: (pageType: PageType) => void;
    userInfo: UserInfo;
    setUserInfo: (userInfo: UserInfo) => void;
}

const PhoneVerificationComponent : React.FC<PhoneVerificationComponentProps> = ({ setPageType, userInfo, setUserInfo }) => {

    const { t } = useTranslation();
    const [otp, setOtp] = useState('');

    const confirmCode = async () => {
        toast.dismiss();

        if(userInfo.confirmationResult) {
            userInfo.confirmationResult.confirm(otp).then(async (result) => {
                const user = result.user;
                const idToken = await user.getIdToken(true);

                setUserInfo({
                    ...userInfo,
                    idToken: idToken
                });

                setPageType(PageType.EMAIL);
            }).catch((error) => {
                console.log(error);
                toast.error(t("invalidConfirmationCode"));
            });
        }
    }

    return (
        <div className={"w-screen h-screen flex flex-col items-center"}>
            <div className={"w-[90%] flex flex-col items-start mt-[7.5%]"}>
                <div className={"w-7 h-7 rounded-full bg-[#FAFAFC] flex items-center justify-center"} onClick={() => setPageType(PageType.PHONE)}>
                    <img src={"./assets/images/back-arrow.svg"} alt={"back"}/>
                </div>
                <div className={"flex flex-col mt-[7.5%] w-full"}>
                    <p className={"font-gilroy-bold text-3xl text-black"}>{t('verifyPhoneHeader')}</p>
                    <p className={"font-gilroy-semibold text-lg text-[#BFBFB9] mt-[1%]"}>{t('enterPhoneVerificationCode')}</p>
                    <div className={"flex items-center justify-center w-full"}>
                        <OTPInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            inputType={"number"}
                            containerStyle={"flex flex-row w-[90%] h-24 items-center justify-between"}
                            inputStyle={"!w-12 h-12 bg-[#F8F8FA] rounded-3xl text-2xl font-gilroy font-medium"}
                            renderInput={(props: InputProps) => <input {...props} />}
                        />
                    </div>
                </div>
                <div className={"mt-[5%] w-full flex flex-row justify-center items-center"}>
                    <div className={"flex flex-row items-center justify-center w-52 h-14 rounded-xl bg-[#E85929] select-none"} onClick={confirmCode}>
                        <p className="font-gilroy-semibold text-xl text-white">
                            {t('nextButton')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PhoneVerificationComponent;
