
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyACzLXR84rQCsU2BgcpWED8Wsuqm01lFto",
    authDomain: "simly.io",
    databaseURL: "https://simly-dedfe-default-rtdb.firebaseio.com",
    projectId: "simly-dedfe",
    storageBucket: "simly-dedfe.appspot.com",
    messagingSenderId: "312948262281",
    appId: "1:312948262281:web:493b31400410771f92cd58",
    measurementId: "G-EQH9Y8HYD6"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
