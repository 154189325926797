import React, {useState} from "react";
import {PageType} from "../../utils/PageType";
import {UserInfo} from "../../dto/UserInfo";
import {CountryItem} from "../../services/dtos/GlobalDTOs";
import {useTranslation} from "react-i18next";

type DestinationConfirmationAlertProp = {
    country: CountryItem;
    setPageType: (pageType: PageType) => void;
    userInfo: UserInfo;
    setUserInfo: (userInfo: UserInfo) => void;
    onClose: () => void;
};

const DestinationConfirmationAlert: React.FC<DestinationConfirmationAlertProp> = ({ country, setPageType, userInfo, setUserInfo, onClose }: DestinationConfirmationAlertProp) => {
    const [isLoading, setLoading] = useState<boolean>(false);

    const { t } = useTranslation();

    const confirmPurchase = async () => {
        setPageType(PageType.REDEEMED);
        setUserInfo({
            ...userInfo,
            country: country
        })
    }

    return (
        <div className="w-screen h-screen flex flex-col items-center justify-center">
            <div className="w-[95%] rounded-3xl flex flex-col items-start bg-white pl-[5%] py-[4%]">
                <img src={country.countryImageURL} className={"mt-[5%] rounded-full w-9 h-9"} alt={"iso"}/>
                <p className={"text-xl font-gilroy-semibold mt-[4%]"}>{t('areYouSure')}</p>
                <p className={"text-sm font-gilroy-regular mt-[1%] text-[#999999]"}>{t('areYouSureDetails')}</p>
                <div className="flex flex-row w-[95%] mt-[8%] items-center justify-center gap-5">
                    <button className="w-32 h-12 bg-[#FC4C02] flex flex-row items-center justify-center rounded-full" onClick={onClose}>
                        <p className={"text-white font-gilroy-semibold"}>{t('no')}
                        </p>
                    </button>
                    <button disabled={isLoading} onClick={() => {
                        onClose()
                        confirmPurchase()
                    }}
                            className="w-32 h-12 bg-[#33C759] flex flex-row items-center justify-center rounded-full">
                        <p className={"text-white font-gilroy-semibold"}>{t('yes')}
                        </p>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DestinationConfirmationAlert;
