import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    freeSim: 'Free 1GB eSIM',
                    freeSimFirstLine: 'Redeem your free 1GB and get',
                    freeSimSecondLine: 'connected as soon as you land',
                    redeemNow: 'Redeem Now',
                    enterPhoneHeader: 'Enter your phone number',
                    enterPhoneDescription: 'Please enter your phone number',
                    enterPhone: 'Phone Number',
                    verifyPhoneHeader: 'Verify your phone number',
                    enterPhoneVerificationCode: 'Enter your phone verification code',
                    enterEmailHeader: 'Enter your email',
                    enterEmailDescription: 'Please enter your email address',
                    enterEmail: 'Email',
                    nextButton: 'Next',
                    emailIncorrect: 'The email provided is incorrect',
                    chooseDestination: 'Choose your destination',
                    searchDestination: 'Search for your destination',
                    localText: 'Local',
                    regionalText: 'Regional',
                    viewAllText: 'view all',
                    successVoilaText: 'Voila! Your 1 Free GB eSIM was just sent to you at',
                    stepsText: 'Steps to access your eSIM',
                    stepOnePart: '1- Go to your',
                    stepOneEmail: 'email',
                    stepOnePartTwo: ' and check the',
                    stepOneLoginCredentials: 'login credentials',
                    stepOnePartThree: 'sent to you',
                    stepTwoPart: '2- Download the',
                    stepTwoApp: 'Simly App',
                    stepTwoPartTwo: 'and go to ‘My eSIMs’ you’ll find the eSIM then press on ‘View’ and follow the instructions to',
                    stepTwoInstall: 'install it',
                    downloadText: 'Download App',
                    alreadyRedeemed: 'Already Redeemed',
                    emailAlreadyUsed: 'The email provided has already redeemed a QR Code!',
                    qrCodeRedeemed: 'That QR code has already been redeemed. Try another one!',
                    loading: 'Loading..',
                    pageNotFound: 'Page Not Found',
                    pageNotFoundDescription: 'The page you are looking for does not exist or has been moved.',
                    areYouSure: 'Are you sure?',
                    areYouSureDetails: 'Are you sure you want to redeem your eSIM for this destination?',
                    no: "No",
                    yes: "Yes",
                    alreadyRedeemedPhone: 'This phone number already redeemed the free 1GB!',
                    waitForConfirmation: 'You need to wait before you can try again.',
                    invalidConfirmationCode: 'You provided an invalid confirmation code.',
                    regionLocked: 'We\'re sorry, this campaign is not available in your region!'
                },
            },
            ar: {
                translation: {
                    freeSim: '1GB eSIM مجانًا',
                    freeSimFirstLine: 'استبدل 1 جيجابايت مجاناً واحصل',
                    freeSimSecondLine: 'على اتصال فوري عند وصولك',
                    redeemNow: 'استبدل الآن',
                    enterPhoneHeader: 'أدخل رقم هاتفك',
                    enterPhoneDescription: 'يرجى إدخال رقم هاتفك',
                    enterPhone: 'رقم الهاتف',
                    verifyPhoneHeader: 'تحقق من رقم هاتفك',
                    enterPhoneVerificationCode: 'أدخل رمز التحقق من الهاتف',
                    enterEmailHeader: 'أدخل بريدك الإلكتروني',
                    enterEmailDescription: 'الرجاء إدخال عنوان بريدك الإلكتروني',
                    enterEmail: 'البريد الإلكتروني',
                    nextButton: 'التالي',
                    emailIncorrect: 'البريد الإلكتروني الذي تم تقديمه غير صحيح',
                    chooseDestination: 'اختر وجهتك',
                    searchDestination: 'ابحث عن وجهتك',
                    localText: 'محلي',
                    regionalText: 'إقليمي',
                    viewAllText: 'عرض الكل',
                    successVoilaText: 'فويلاء! تم إرسال eSIM الخاص بك 1 جيجابايت مجانًا إليك في',
                    stepsText: 'خطوات للوصول إلى eSIM الخاص بك',
                    stepOnePart: '1- انتقل إلى بريدك الإلكتروني وتحقق من',
                    stepOneEmail: 'البريد الإلكتروني',
                    stepOnePartTwo: 'وتحقق من',
                    stepOneLoginCredentials: 'بيانات الدخول',
                    stepOnePartThree: ' التي تم إرسالها إليك',
                    stepTwoPart: '2- قم بتنزيل',
                    stepTwoApp: 'تطبيق Simly',
                    stepTwoPartTwo: 'وانتقل إلى ‘بطاقات الشريحة الإلكترونية الخاصة بي’ ستجد بطاقة الشريحة الإلكترونية ثم اضغط على ‘عرض’ واتبع التعليمات ل',
                    stepTwoInstall: 'تثبيتها',
                    downloadText: 'قم بتنزيل التطبيق',
                    alreadyRedeemed: 'تم استبدالها بالفعل',
                    emailAlreadyUsed: 'البريد الإلكتروني الذي تم توفيره قد تم تبديله بالفعل بواسطة رمز الاستجابة السريعة!',
                    qrCodeRedeemed: 'تم استبدال رمز الاستجابة السريعة هذا بالفعل. جرب رمزًا آخر!',
                    loading: 'جاري التحميل...',
                    pageNotFound: 'الصفحة غير موجودة',
                    pageNotFoundDescription: 'الصفحة التي تبحث عنها غير موجودة أو تم نقلها.',
                    areYouSure: 'هل أنت متأكد أنك تريد استرداد بطاقة eSIM الخاصة بك لهذه الوجهة؟',
                    areYouSureDetails: 'هل تريد المتابعة في شراء eSIM؟',
                    no: "لا",
                    yes: "نعم",
                    alreadyRedeemedPhone: 'هذا الرقم قام بالفعل بتحصيل 1 جيجابايت مجانًا!',
                    waitForConfirmation: 'يجب عليك الانتظار قبل أن تتمكن من المحاولة مرة أخرى.',
                    invalidConfirmationCode: 'لقد قدمت رمز تأكيد غير صالح.',
                    regionLocked: 'نأسف، هذه الحملة غير متوفرة في منطقتك!'
                },
            },
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
